/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { LexiconDoc, Lexicons } from '@atproto/lexicon'

export const schemaDict = {
  PlaceStreamDefs: {
    lexicon: 1,
    id: 'place.stream.defs',
    defs: {
      blockView: {
        type: 'object',
        required: ['uri', 'cid', 'blocker', 'record', 'indexedAt'],
        properties: {
          uri: {
            type: 'string',
            format: 'at-uri',
          },
          cid: {
            type: 'string',
            format: 'cid',
          },
          blocker: {
            type: 'ref',
            ref: 'lex:app.bsky.actor.defs#profileViewBasic',
          },
          record: {
            type: 'ref',
            ref: 'lex:app.bsky.graph.block',
          },
          indexedAt: {
            type: 'string',
            format: 'datetime',
          },
        },
      },
    },
  },
  PlaceStreamSegment: {
    lexicon: 1,
    id: 'place.stream.segment',
    defs: {
      main: {
        type: 'record',
        description: 'Media file representing a segment of a livestream',
        key: 'tid',
        record: {
          type: 'object',
          required: ['id', 'signingKey', 'startTime', 'creator'],
          properties: {
            id: {
              type: 'string',
              description: 'Unique identifier for the segment',
            },
            signingKey: {
              type: 'string',
              description: 'The DID of the signing key used for this segment',
            },
            startTime: {
              type: 'string',
              format: 'datetime',
              description: 'When this segment started',
            },
            creator: {
              type: 'string',
              format: 'did',
            },
            video: {
              type: 'array',
              items: {
                type: 'ref',
                ref: 'lex:place.stream.segment#video',
              },
            },
            audio: {
              type: 'array',
              items: {
                type: 'ref',
                ref: 'lex:place.stream.segment#audio',
              },
            },
          },
        },
      },
      audio: {
        type: 'object',
        required: ['codec', 'rate', 'channels'],
        properties: {
          codec: {
            type: 'string',
            enum: ['opus'],
          },
          rate: {
            type: 'integer',
          },
          channels: {
            type: 'integer',
          },
        },
      },
      video: {
        type: 'object',
        required: ['codec', 'width', 'height'],
        properties: {
          codec: {
            type: 'string',
            enum: ['h264'],
          },
          width: {
            type: 'integer',
          },
          height: {
            type: 'integer',
          },
        },
      },
    },
  },
  PlaceStreamLivestream: {
    lexicon: 1,
    id: 'place.stream.livestream',
    defs: {
      main: {
        type: 'record',
        description: 'Record announcing a livestream is happening',
        key: 'tid',
        record: {
          type: 'object',
          required: ['title', 'createdAt'],
          properties: {
            title: {
              type: 'string',
              maxLength: 1400,
              maxGraphemes: 140,
              description:
                'The title of the livestream, as it will be announced to followers.',
            },
            url: {
              type: 'string',
              format: 'uri',
              description:
                'The URL where this stream can be found. This is primarily a hint for other Streamplace nodes to locate and replicate the stream.',
            },
            createdAt: {
              type: 'string',
              format: 'datetime',
              description:
                'Client-declared timestamp when this livestream started.',
            },
            post: {
              type: 'ref',
              ref: 'lex:com.atproto.repo.strongRef',
              description:
                'The post that announced this livestream. Used for chat replies.',
            },
          },
        },
      },
      livestreamView: {
        type: 'object',
        required: ['uri', 'cid', 'author', 'record', 'indexedAt'],
        properties: {
          uri: {
            type: 'string',
            format: 'at-uri',
          },
          cid: {
            type: 'string',
            format: 'cid',
          },
          author: {
            type: 'ref',
            ref: 'lex:app.bsky.actor.defs#profileViewBasic',
          },
          record: {
            type: 'unknown',
          },
          indexedAt: {
            type: 'string',
            format: 'datetime',
          },
        },
      },
      viewerCount: {
        type: 'object',
        required: ['count'],
        properties: {
          count: {
            type: 'integer',
          },
        },
      },
      streamplaceAnything: {
        type: 'object',
        required: ['livestream'],
        properties: {
          livestream: {
            type: 'union',
            refs: [
              'lex:place.stream.livestream#livestreamView',
              'lex:place.stream.livestream#viewerCount',
              'lex:place.stream.defs#blockView',
            ],
          },
        },
      },
    },
  },
  PlaceStreamKey: {
    lexicon: 1,
    id: 'place.stream.key',
    defs: {
      main: {
        type: 'record',
        description:
          'Record linking an atproto identity with a stream signing key',
        key: 'tid',
        record: {
          type: 'object',
          required: ['signingKey', 'createdAt'],
          properties: {
            signingKey: {
              type: 'string',
              maxLength: 57,
              minLength: 57,
              description: 'The did:key signing key for the stream.',
            },
            createdAt: {
              type: 'string',
              format: 'datetime',
              description:
                'Client-declared timestamp when this key was created.',
            },
          },
        },
      },
    },
  },
} as const satisfies Record<string, LexiconDoc>

export const schemas = Object.values(schemaDict)
export const lexicons: Lexicons = new Lexicons(schemas)
export const ids = {
  PlaceStreamDefs: 'place.stream.defs',
  PlaceStreamSegment: 'place.stream.segment',
  PlaceStreamLivestream: 'place.stream.livestream',
  PlaceStreamKey: 'place.stream.key',
}
