{
  "name": "streamplace",
  "main": "./src/entrypoint.tsx",
  "version": "0.5.9",
  "runtimeVersion": "0.4.3",
  "scripts": {
    "upgrade:tamagui": "yarn up '*tamagui*'@latest '@tamagui/*'@latest",
    "upgrade:tamagui:canary": "yarn up '*tamagui*'@canary '@tamagui/*'@canary",
    "start": "npx expo start -c --port 38081",
    "android": "expo run:android --port 38081 \"$@\"",
    "ios": "expo run:ios --port 38081",
    "web": "npx expo start --web --port 38081",
    "test": "jest --watchAll",
    "build": "yarn run build:web && yarn run prebuild",
    "build:web": "yarn run export && node exportClientExpoConfig.js > dist/expoConfig.json",
    "export": "expo export --dump-sourcemap || expo export --dump-sourcemap",
    "check": "bash -c 'set -euo pipefail;export OUT=$(mktemp -d); npx tsc -p . --outDir $OUT; rm -rf $OUT'",
    "prebuild": "EXPO_NO_GIT_STATUS=1 expo prebuild --clean && sed -i.bak 's/org.gradle.jvmargs=-Xmx2048m -XX:MaxMetaspaceSize=512m/org.gradle.jvmargs=-Xmx8192m -XX:MaxMetaspaceSize=2048m/' android/gradle.properties && yarn run find-node",
    "postinstall": "which pod && yarn run postinstall-ios || echo 'not a mac, not installing pods'",
    "postinstall-ios": "cd ios && pod install && yarn run find-node",
    "find-node": "node -p '`NODE_BINARY=${process.argv[0]}`' > ios/.xcode.env.local",
    "code-signing-dev": "mkdir -p code-signing-dev/keys code-signing-dev/certs && expo-updates codesigning:generate --key-output-directory code-signing-dev/keys --certificate-output-directory code-signing-dev/certs --certificate-validity-duration-years 10 --certificate-common-name 'Streamplace'"
  },
  "jest": {
    "preset": "jest-expo"
  },
  "dependencies": {
    "@aquareum/atproto-oauth-client-react-native": "^0.0.1",
    "@atproto-labs/pipe": "^0.1.0",
    "@atproto/crypto": "^0.4.2",
    "@atproto/jwk-jose": "^0.1.2",
    "@atproto/oauth-client": "^0.3.1",
    "@bacons/text-decoder": "^0.0.0",
    "@rainbow-me/rainbowkit": "2.2.0",
    "@react-native-firebase/app": "^21.3.0",
    "@react-native-firebase/messaging": "^21.3.0",
    "@react-navigation/bottom-tabs": "^6.6.1",
    "@react-navigation/drawer": "^6.7.2",
    "@react-navigation/native": "^6.1.18",
    "@react-navigation/native-stack": "^6.11.0",
    "@reduxjs/toolkit": "^2.3.0",
    "@tamagui/config": "^1.123.17",
    "@tamagui/lucide-icons": "^1.123.17",
    "@tamagui/toast": "^1.123.17",
    "@tanstack/react-query": "^5.59.19",
    "@zxing/browser": "^0.1.5",
    "@zxing/library": "^0.21.3",
    "abortcontroller-polyfill": "^1.7.6",
    "babel-preset-expo": "~12.0.0",
    "burnt": "^0.12.2",
    "chrono-node": "^2.7.7",
    "event-target-shim": "^6.0.2",
    "expo": "~52.0.8",
    "expo-build-properties": "~0.13.1",
    "expo-dev-client": "~5.0.3",
    "expo-file-system": "~18.0.4",
    "expo-font": "~13.0.1",
    "expo-keep-awake": "~14.0.2",
    "expo-linking": "~7.0.3",
    "expo-notifications": "~0.29.8",
    "expo-splash-screen": "~0.29.11",
    "expo-sqlite": "~15.0.3",
    "expo-status-bar": "~2.0.0",
    "expo-system-ui": "~4.0.3",
    "expo-updates": "~0.26.7",
    "expo-video": "~2.0.1",
    "expo-web-browser": "^14.0.1",
    "hls.js": "^1.5.17",
    "jose": "^5.9.6",
    "multiformats": "^13.3.1",
    "qrcode": "^1.5.4",
    "quietjs-bundle": "^0.1.3",
    "react": "18.3.1",
    "react-dom": "18.3.1",
    "react-native": "0.76.2",
    "react-native-gesture-handler": "~2.20.2",
    "react-native-markdown-display": "^7.0.2",
    "react-native-quick-crypto": "^0.7.7",
    "react-native-reanimated": "~3.16.1",
    "react-native-safe-area-context": "4.12.0",
    "react-native-screens": "~4.1.0",
    "react-native-svg": "15.8.0",
    "react-native-web": "^0.19.13",
    "react-native-webrtc": "^124.0.4",
    "react-native-webview": "13.12.4",
    "react-redux": "^9.1.2",
    "react-use-websocket": "^4.13.0",
    "rtcaudiodevice": "git+https://github.com/streamplace/RTCAudioDevice.git#7b4659fe845545d366623cbc813936987144b76f",
    "tamagui": "^1.123.17",
    "ua-parser-js": "^2.0.0-rc.1",
    "uuid": "^11.0.2",
    "viem": "^2.21.40",
    "wagmi": "2.12.25"
  },
  "devDependencies": {
    "@babel/core": "^7.26.0",
    "@babel/plugin-proposal-export-default-from": "^7.25.9",
    "@babel/plugin-syntax-export-default-from": "^7.25.9",
    "@babel/plugin-transform-flow-strip-types": "^7.25.9",
    "@babel/plugin-transform-private-methods": "^7.25.9",
    "@babel/plugin-transform-private-property-in-object": "^7.25.9",
    "@babel/plugin-transform-runtime": "^7.25.9",
    "@config-plugins/react-native-webrtc": "^10.0.0",
    "@expo/metro-config": "~0.19.0",
    "@expo/metro-runtime": "~4.0.0",
    "@tamagui/babel-plugin": "^1.123.17",
    "@tamagui/metro-plugin": "^1.123.17",
    "@types/babel__plugin-transform-runtime": "^7",
    "@types/qrcode": "^1",
    "@types/react": "~18.3.12",
    "@types/uuid": "^10.0.0",
    "typescript": "~5.3.3"
  },
  "private": true
}
