/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../util'
import { lexicons } from '../../../lexicons'
import { CID } from 'multiformats/cid'
import * as AppBskyActorDefs from '@atproto/api/src/client/types/app/bsky/actor/defs'
import * as AppBskyGraphBlock from '@atproto/api/src/client/types/app/bsky/graph/block'

export interface BlockView {
  uri: string
  cid: string
  blocker: AppBskyActorDefs.ProfileViewBasic
  record: AppBskyGraphBlock.Record
  indexedAt: string
  [k: string]: unknown
}

export function isBlockView(v: unknown): v is BlockView {
  return (
    isObj(v) && hasProp(v, '$type') && v.$type === 'place.stream.defs#blockView'
  )
}

export function validateBlockView(v: unknown): ValidationResult {
  return lexicons.validate('place.stream.defs#blockView', v)
}
