/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../util'
import { lexicons } from '../../../lexicons'
import { CID } from 'multiformats/cid'

export interface Record {
  /** Unique identifier for the segment */
  id: string
  /** The DID of the signing key used for this segment */
  signingKey: string
  /** When this segment started */
  startTime: string
  creator: string
  video?: Video[]
  audio?: Audio[]
  [k: string]: unknown
}

export function isRecord(v: unknown): v is Record {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    (v.$type === 'place.stream.segment#main' ||
      v.$type === 'place.stream.segment')
  )
}

export function validateRecord(v: unknown): ValidationResult {
  return lexicons.validate('place.stream.segment#main', v)
}

export interface Audio {
  codec: 'opus'
  rate: number
  channels: number
  [k: string]: unknown
}

export function isAudio(v: unknown): v is Audio {
  return (
    isObj(v) && hasProp(v, '$type') && v.$type === 'place.stream.segment#audio'
  )
}

export function validateAudio(v: unknown): ValidationResult {
  return lexicons.validate('place.stream.segment#audio', v)
}

export interface Video {
  codec: 'h264'
  width: number
  height: number
  [k: string]: unknown
}

export function isVideo(v: unknown): v is Video {
  return (
    isObj(v) && hasProp(v, '$type') && v.$type === 'place.stream.segment#video'
  )
}

export function validateVideo(v: unknown): ValidationResult {
  return lexicons.validate('place.stream.segment#video', v)
}
