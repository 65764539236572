/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../util'
import { lexicons } from '../../../lexicons'
import { CID } from 'multiformats/cid'
import * as ComAtprotoRepoStrongRef from '@atproto/api/src/client/types/com/atproto/repo/strongRef'
import * as AppBskyActorDefs from '@atproto/api/src/client/types/app/bsky/actor/defs'
import * as PlaceStreamDefs from './defs'

export interface Record {
  /** The title of the livestream, as it will be announced to followers. */
  title: string
  /** The URL where this stream can be found. This is primarily a hint for other Streamplace nodes to locate and replicate the stream. */
  url?: string
  /** Client-declared timestamp when this livestream started. */
  createdAt: string
  post?: ComAtprotoRepoStrongRef.Main
  [k: string]: unknown
}

export function isRecord(v: unknown): v is Record {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    (v.$type === 'place.stream.livestream#main' ||
      v.$type === 'place.stream.livestream')
  )
}

export function validateRecord(v: unknown): ValidationResult {
  return lexicons.validate('place.stream.livestream#main', v)
}

export interface LivestreamView {
  uri: string
  cid: string
  author: AppBskyActorDefs.ProfileViewBasic
  record: {}
  indexedAt: string
  [k: string]: unknown
}

export function isLivestreamView(v: unknown): v is LivestreamView {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'place.stream.livestream#livestreamView'
  )
}

export function validateLivestreamView(v: unknown): ValidationResult {
  return lexicons.validate('place.stream.livestream#livestreamView', v)
}

export interface ViewerCount {
  count: number
  [k: string]: unknown
}

export function isViewerCount(v: unknown): v is ViewerCount {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'place.stream.livestream#viewerCount'
  )
}

export function validateViewerCount(v: unknown): ValidationResult {
  return lexicons.validate('place.stream.livestream#viewerCount', v)
}

export interface StreamplaceAnything {
  livestream:
    | LivestreamView
    | ViewerCount
    | PlaceStreamDefs.BlockView
    | { $type: string; [k: string]: unknown }
  [k: string]: unknown
}

export function isStreamplaceAnything(v: unknown): v is StreamplaceAnything {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'place.stream.livestream#streamplaceAnything'
  )
}

export function validateStreamplaceAnything(v: unknown): ValidationResult {
  return lexicons.validate('place.stream.livestream#streamplaceAnything', v)
}
